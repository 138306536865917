import Vue from "vue";
import Router from "vue-router";
import goTo from "vuetify/es5/services/goto";
//import store from "./store/store"

Vue.use(Router);

const router = new Router({
  mode: "history",
  base: process.env.BASE_URL,
  // This is for the scroll top when click on any router link
  scrollBehavior: (to, from, savedPosition) => {
    let scrollTo = 0;

    if (to.hash) {
      scrollTo = to.hash;
    } else if (savedPosition) {
      scrollTo = savedPosition.y;
    }

    return goTo(scrollTo);
  },
  // This is for the scroll top when click on any router link
  routes: [
    {
      path: "/",
      redirect: "main",
      component: () => import("@/layouts/full-layout/Layout"),
      children: [
        {
          name: "Main",
          path: "main",
          component: () => import("@/views/business/Main"),
          meta: {requireAuth: true}
          
        },
        //ADMINISTRATION
        {
          name: "User",
          path: "administration/user",
          component: () => import("@/views/administration/User/User"),
          meta: {requireAuth: true}
        },
        {
          name: "Rol",
          path: "administration/rol",
          component: () => import("@/views/administration/Rol/Rol"),
          meta: {requireAuth: true}
        },
        {
          name: "Business",
          path: "administration/business",
          component: () => import("@/views/administration/Business/Business"),
          meta: {requireAuth: true}
        },
        //DATA
        {
          name: "Customer",
          path: "data/customer",
          component: () => import("@/views/administration/Customer/Customer"),
          meta: {requireAuth: true}
        },
        {
          name: "Contact",
          path: "data/contact",
          component: () => import("@/views/administration/Contact/Contact"),
          meta: {requireAuth: true}
        },
        {
          name: "Unit",
          path: "data/unit",
          component: () => import("@/views/logistic/Unit/Unit"),
          meta: {requireAuth: true}
        },
        {
          name: "Bank",
          path: "data/bank",
          component: () => import("@/views/accounting/Bank/Bank"),
          meta: {requireAuth: true}
        },
        {
          name: "BankAccounts",
          path: "data/bankaccount",
          component: () => import("@/views/accounting/BankAccounts/BankAccounts"),
          meta: {requireAuth: true}
        },
        //LOGISTICA
        {
          name: "Chip",
          path: "logistics/operatorchip",
          component: () => import("@/views/logistic/Chip/OperatorChip"),
          meta: {requireAuth: true}
        },
        {
          name: "Simcard",
          path: "logistics/simcard",
          component: () => import("@/views/logistic/Chip/Simcard"),
          meta: {requireAuth: true}
        },
        {
          name: "Avl-Gps",
          path: "logistics/avlgps",
          component: () => import("@/views/logistic/Avl-gps/AvlGps"),
          meta: {requireAuth: true}
        },
        {
          name: "Accessory",
          path: "logistics/accessory",
          component: () => import("@/views/logistic/Unit/Accessory"),
          meta: {requireAuth: true}
        },
        //VENTAS
        {
          name: "Quotation",
          path: "sell/quotation",
          component: () => import("@/views/sales/Quotation/Quotation"),
          meta: {requireAuth: true}
        },
        {
          name: "Sale",
          path: "sell/sale",
          component: () => import("@/views/sales/Sale/Sale"),
          meta: {requireAuth: true}
        },
        {
          name: "SaleInvoice",
          path: "sell/saleinvoice",
          component: () => import("@/views/sales/SaleInvoice/SaleInvoice"),
          meta: {requireAuth: true}
        },
        {
          name: "Payments",
          path: "sell/payments",
          component: () => import("@/views/accounting/Payments/Payments"),
          meta: {requireAuth: true}
        },
        {
          name: "CreditNote",
          path: "sell/creditnote",
          component: () => import("@/views/sales/CreditNote/CreditNote"),
          meta: {requireAuth: true}
        },
        //Contabilidad
        {
          name: "Supplier",
          path: "accounting/supplier",
          component: () => import("@/views/administration/Supplier/Supplier"),
          meta: {requireAuth: true}
        },
        {
          name: "Autodetraction",
          path: "accounting/autodetraction",
          component: () => import("@/views/sales/AutoDetraction/AutoDetraction"),
          meta: {requireAuth: true}
        },
        {
          name: "TxtSale",
          path: "accounting/txt",
          component: () => import("@/views/sales/Txt/Txt"),
          meta: {requireAuth: true}
        },
        {
          name: "PurchaseDetraction",
          path: "accounting/purchase",
          component: () => import("@/views/purchase/Purchase/PurchaseDetraction"),
          meta: {requireAuth: true}
        },
        {
          name: "TxtPurchase",
          path: "accounting/txtpurchase",
          component: () => import("@/views/purchase/Txt/Txt"),
          meta: {requireAuth: true}
        },
        {
          name: "BankLoan",
          path: "accounting/bankloan",
          component: () => import("@/views/loan/BankLoan/BankLoan"),
          meta: {requireAuth: true}
        },
        {
          name: "SummaryTable",
          path: "accounting/summarytable",
          component: () => import("@/views/loan/Summary/SummaryTable"),
          meta: {requireAuth: true}
        },
        {
          name: "SummaryCalendar",
          path: "accounting/summarycalendar",
          component: () => import("@/views/loan/Summary/SummaryCalendar"),
          meta: {requireAuth: true}
        },
        //
        {
          name: "ContactType",
          path: "maintenance/contacttype",
          component: () => import("@/views/administration/Contact/ContactType"),
          meta: {requireAuth: true}
        },
        //CONFIGURACION
        {
          name: "AvlState",
          path: "maintenance/stateavl",
          component: () => import("@/views/logistic/Avl-gps/AvlState"),
          meta: {requireAuth: true}
        },
        {
          name: "ServiceState",
          path: "maintenance/stateservice",
          component: () => import("@/views/logistic/Unit/ServiceState"),
          meta: {requireAuth: true}
        },
        {
          name: "UnitState",
          path: "maintenance/stateunit",
          component: () => import("@/views/logistic/Unit/UnitState"),
          meta: {requireAuth: true}
        },
        {
          name: "ChipState",
          path: "maintenance/statechip",
          component: () => import("@/views/logistic/Chip/ChipState"),
          meta: {requireAuth: true}
        },
        {
          name: "AvlBrand",
          path: "maintenance/brandavl",
          component: () => import("@/views/logistic/Avl-gps/AvlBrand"),
          meta: {requireAuth: true}
        },
        {
          name: "AvlModel",
          path: "maintenance/modelavl",
          component: () => import("@/views/logistic/Avl-gps/AvlModel"),
          meta: {requireAuth: true}
        },
        {
          name: "Platform",
          path: "maintenance/platform",
          component: () => import("@/views/logistic/Unit/Platform"),
          meta: {requireAuth: true}
        },
        {
          name: "Entry",
          path: "maintenance/entry",
          component: () => import("@/views/logistic/Unit/Entry"),
          meta: {requireAuth: true}
        },
        {
          name: "ServiceType",
          path: "maintenance/servicetype",
          component: () => import("@/views/logistic/Unit/ServiceType"),
          meta: {requireAuth: true}
        },
        {
          name: "Observation",
          path: "maintenance/observation",
          component: () => import("@/views/logistic/Unit/Observation"),
          meta: {requireAuth: true}
        },
        {
          name: "Price",
          path: "maintenance/price",
          component: () => import("@/views/logistic/Unit/Price"),
          meta: {requireAuth: true}
        },
        {
          name: "TransmissionType",
          path: "maintenance/retransmissiontype",
          component: () => import("@/views/logistic/Unit/TransmissionType"),
          meta: {requireAuth: true}
        },
        {
          name: "EquipmentMode",
          path: "maintenance/equipmentmode",
          component: () => import("@/views/logistic/Unit/EquipmentMode"),
          meta: {requireAuth: true}
        },
        {
          name: "IssuedDocuments",
          path: "maintenance/issueddocuments",
          component: () => import("@/views/logistic/Unit/IssuedDocuments"),
          meta: {requireAuth: true}
        },
        {
          name: "AttachedDocuments",
          path: "maintenance/attacheddocuments",
          component: () => import("@/views/logistic/Unit/AttachedDocuments"),
          meta: {requireAuth: true}
        },
        {
          name: "Consulting",
          path: "maintenance/consulting",
          component: () => import("@/views/logistic/Unit/Consulting"),
          meta: {requireAuth: true}
        },
        {
          name: "Support",
          path: "maintenance/support",
          component: () => import("@/views/logistic/Unit/Support"),
          meta: {requireAuth: true}
        },
        {
          name: "Kit",
          path: "maintenance/service",
          component: () => import("@/views/sales/Kit/Kit"),
          meta: {requireAuth: true}
        },
        {
          name: "Pack",
          path: "maintenance/kit",
          component: () => import("@/views/sales/Pack/Pack"),
          meta: {requireAuth: true}
        },
        
        {
          name: "Serie",
          path: "maintenance/serie",
          component: () => import("@/views/sales/Serie/Serie"),
          meta: {requireAuth: true}
        },
        {
          name: "PettyCashes",
          path: "pettycash",
          component: () => import("@/views/pettyCash/PettyCashes/PettyCashes"),
          meta: {requireAuth: true}
        },
        {
          name: "PettyCashesMovement",
          path: "pettycash/:idPettyCash/movements",
          component: () => import("@/views/pettyCash/Movements/Movements"),
          meta: {requireAuth: true}
        },
        {
          name: "ScheduleBankLoan",
          path: "accounting/bankloan/:idBankLoan/schedule",
          component: () => import("@/views/loan/Schedule/Schedule"),
          meta: {requireAuth: true}
        },
        {
          name: "Accrued",
          path: "accounting/bankloan/:idBankLoan/accrued",
          component: () => import("@/views/loan/Accrued/Accrued"),
          meta: {requireAuth: true}
        },
        {
          name: "MyProfile",
          path: "myprofile",
          component: () => import("@/views/myProfile/MyProfile/MyProfile"),
          meta: {requireAuth: true}
        },
        {
          name: "Worker",
          path: "payroll/worker",
          component: () => import("@/views/payroll/Worker/Worker"),
          meta: {requireAuth: true}
        },
        {
          name: "PensionFunds",
          path: "payroll/pension",
          component: () => import("@/views/payroll/PensionFunds/PensionFunds"),
          meta: {requireAuth: true}
        },
        {
          name: "Afp",
          path: "payroll/afp",
          component: () => import("@/views/payroll/Afp/Afp"),
          meta: {requireAuth: true}
        },
        {
          name: "Setting",
          path: "payroll/setting",
          component: () => import("@/views/payroll/Setting/Setting"),
          meta: {requireAuth: true}
        },
        {
          name: "AccountingPayroll",
          path: "payroll/accounting",
          component: () => import("@/views/payroll/Accounting/Accounting"),
          meta: {requireAuth: true}
        },
        {
          name: "AccountingPayrollDetails",
          path: "payroll/accounting/:idAccountingPayroll/details",
          component: () => import("@/views/payroll/Accounting/AccountingDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "SalaryBonuses",
          path: "payroll/salarybonuses",
          component: () => import("@/views/payroll/SalaryBonuses/SalaryBonuses"),
          meta: {requireAuth: true}
        },
        {
          name: "SalaryBonusesDetails",
          path: "payroll/salarybonuses/:idSalaryBonuses/details",
          component: () => import("@/views/payroll/SalaryBonuses/SalaryBonusesDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "FeeReceipts",
          path: "payroll/feereceipts",
          component: () => import("@/views/payroll/FeeReceipts/FeeReceipts"),
          meta: {requireAuth: true}
        },
        {
          name: "FeeReceiptsDetails",
          path: "payroll/feereceipts/:idFeeReceipts/details",
          component: () => import("@/views/payroll/FeeReceipts/FeeReceiptsDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "PayrollPayments",
          path: "payroll/payrollpayments",
          component: () => import("@/views/payroll/PayrollPayments/PayrollPayments"),
          meta: {requireAuth: true}
        },
        {
          name: "PayrollPaymentsDetails",
          path: "payroll/payrollpayments/:idPayrollPayments/details",
          component: () => import("@/views/payroll/PayrollPayments/PayrollPaymentsDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "Holidays",
          path: "payroll/holidays",
          component: () => import("@/views/payroll/Holidays/Holidays"),
          meta: {requireAuth: true}
        },
        {
          name: "HolidaysDetails",
          path: "payroll/holidays/:idHolidays/details",
          component: () => import("@/views/payroll/Holidays/HolidaysDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "WageSummary",
          path: "payroll/wagesummary",
          component: () => import("@/views/payroll/WageSummary/WageSummary"),
          meta: {requireAuth: true}
        },
        {
          name: "WageTables",
          path: "payroll/wagesummary/:idWage/tables",
          component: () => import("@/views/payroll/WageSummary/WageTables"),
          meta: {requireAuth: true}
        },
        {
          name: "WageTableDetails",
          path: "payroll/wagesummary/:idWage/tables/:idWageTable/details",
          component: () => import("@/views/payroll/WageSummary/WageTableDetails"),
          meta: {requireAuth: true}
        },
        {
          name: "Category",
          path: "logistics/category",
          component: () => import("@/views/logistic/Product/Category"),
          meta: {requireAuth: true}
        },
        {
          name: "ProductCode",
          path: "logistics/productcode",
          component: () => import("@/views/logistic/Product/ProductCode"),
          meta: {requireAuth: true}
        },
        {
          name: "AvlFirmware",
          path: "logistics/avlfirmware",
          component: () => import("@/views/logistic/Avl-gps/AvlFirmware"),
          meta: {requireAuth: true}
        },
        {
          name: "WarehouseType",
          path: "logistics/warehouseType",
          component: () => import("@/views/logistic/WarehouseType/WarehouseType"),
          meta: {requireAuth: true}
        },
        {
          name: "Warehouse",
          path: "logistics/warehouse",
          component: () => import("@/views/logistic/Warehouse/Warehouse"),
          meta: {requireAuth: true}
        },
        {
          name: "WarehouseAllGuides",
          path: "logistics/warehouse/warehouseAllGuides",
          component: () => import("@/views/logistic/Warehouse/WarehouseAllGuides"),
          meta: {requireAuth: true}
        },
        {
          name: "WarehouseDetails",
          path: "logistics/warehouse/:idWarehouse/details",
          component: () => import("@/views/logistic/Warehouse/WarehouseDetail"),
          meta: {requireAuth: true}
        },
        {
          name: "IncomeGuide",
          path: "logistics/incomeguide",
          component: () => import("@/views/logistic/IncomeGuide/IncomeGuide"),
          meta: {requireAuth: true}
        },
        {
          name: "IncomeGuideDetails",
          path: "logistics/incomeguide/:idIncomeguide/details",
          component: () => import("@/views/logistic/IncomeGuide/IncomeGuideDetail"),
          meta: {requireAuth: true}
        },
        {
          name: "OutcomeGuide",
          path: "logistics/outcomeguide",
          component: () => import("@/views/logistic/OutcomeGuide/OutcomeGuide"),
          meta: {requireAuth: true}
        },
        {
          name: "OutcomeGuideDetails",
          path: "logistics/outcomeguide/:idOutcomeguide/details",
          component: () => import("@/views/logistic/OutcomeGuide/OutcomeGuideDetail"),
          meta: {requireAuth: true}
        },
        {
          name: "AccessoryModel",
          path: "logistics/accessorymodel",
          component: () => import("@/views/logistic/Unit/AccessoryModel"),
          meta: {requireAuth: true}
        },
        {
          name: "UnitLogistic",
          path: "logistics/unit",
          component: () => import("@/views/logistic/Unit/Unit_logistic"),
          meta: {requireAuth: true}
        },
        {
          name: "ChipPlan",
          path: "logistics/chipplan",
          component: () => import("@/views/logistic/Chip/Plans"),
          meta: {requireAuth: true}
        },
        {
          name: "AccountantGuide",
          path: "accounting/accountantguide",
          component: () => import("@/views/logistic/AccountantGuide/AccountantGuide"),
          meta: {requireAuth: true}
        },
        {
          name: "AvlGpsDelete",
          path: "logistics/avlgps/:idAvlGps/avlgpsdelete",
          component: () => import("@/views/logistic/Avl-gps/AvlGpsDelete"),
          meta: {requireAuth: true}
        },
        {
          name: "SimcardDelete",
          path: "logistics/simcard/:idSimcard/simcarddelete",
          component: () => import("@/views/logistic/Chip/SimcardDelete"),
          meta: {requireAuth: true}
        },
        {
          name: "AccessoryDelete",
          path: "logistics/accessory/:idAccessory/accessorydelete",
          component: () => import("@/views/logistic/Unit/AccessoryDelete"),
          meta: {requireAuth: true}
        },
        {
          name: "Schedule",
          path: "schedule",
          component: () => import("@/views/schedule/Schedule/Schedule"),
          meta: {requireAuth: true}
        },
        {
          name: "Historical",
          path: "historical",
          component: () => import("@/views/schedule/Historical/Historical"),
          meta: {requireAuth: true}
        },
      ],
    },
    {
      path: '/login',
      name: 'Login',
      component: () => import(/* webpackChunkName: "about" */ './views/Login.vue')
    },

    //shared
    {
      path: "*",
      component: () => import("./views/shared/Error.vue"),
    },
    //business
  ],
});

import NProgress from "nprogress";

router.beforeResolve((to, from, next) => {
  // If this isn't an initial page load.
  if (to.name) {
    // Start the route progress bar.
    NProgress.start(800);
  }
  next();
});

router.beforeEach((to,from,next)=>{
  //const protectedRoute = to.matched.some(record => record.meta.requireAuth)
  if(to.name !== 'Login' && localStorage.getItem('token') === null){
    next({name: 'Login'})
  }else{
    next();
  }
});

router.afterEach(() => {
  // Complete the animation of the route progress bar.
  NProgress.done();
});

export default router;
